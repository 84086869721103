<template>
  <div>
    <div class="LogListTitleClass">
      <v-row>
        <v-col md="2" class="caption grey--text"> Дата события </v-col>
        <v-col md="1" class="caption grey--text"> Обновлено </v-col>
        <v-col md="1" class="caption grey--text"> Обращений </v-col>
        <v-col md="1" class="caption grey--text"> Тип </v-col>
        <v-col md="7" class="caption grey--text"> Сообщение </v-col>
      </v-row>
    </div>
    <v-expansion-panels hover>
      <LogListItem v-for="log in logs" :key="log.id" :log="log" />
    </v-expansion-panels>
  </div>
</template>

<script>
import LogListItem from './LogListItem';

export default {
  props: {
    logs: {
      type: Array,
      required: true
    }
  },
  components: {
    LogListItem
  }
};
</script>
<style scoped>
.LogListTitleClass {
  padding: 12px 24px 12px 12px;
}
</style>
